<template>
    <div class="msp-tabella-risultato">
        <v-data-table
            :headers="headers"
            :items="data"
            :items-per-page="5"
            class="striped elevation-1"
            :mobile-breakpoint="0"
            disable-pagination
            hide-default-footer
            @click:row="onClickRow"
        >
            <template v-for="header in headers" v-slot:[`header.${header.value}`]>
                    <span  :key="header.value" class='header-multiline' v-html="renderHeader(header)"></span>
</template>
            <template v-slot:item.dataRecord="{ item }">
                <div class="data">{{formatDate(item.dataRecord)}}</div>
</template>
<template v-slot:item.risultato="{ item }">
    <div class="result"><span class="badge-record" v-html="renderBest(item)"></span> {{item.stringRisultato}} </div>
</template>
<template v-slot:item.passo="{ item }">
    <div class="detail">{{item.stringPasso}}</div>
</template>
</v-data-table>
    <v-dialog
      v-model="dialog"
      max-width="400"
    >
      <v-card v-if="item">
       <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title>{{stringTarget}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-title class="text-h5">
{{item.stringRisultato}} 
        </v-card-title>

        <v-card-text>

            <template v-if="item.risultato">
    <div class="data"><span v-if="item.wattkg">({{item.wattkg}})</span> <span class="badge-record" v-html="renderBest(item)"></span></div>
</template>
<template v-if="item.passo">
    <div class="data">{{item.stringPasso}}</div>
</template>
{{formatDate(item.dataRecord)}}
        </v-card-text>

        <v-card-actions>

          <v-btn
            color="red darken-1"
            text
            @click="deleteItem(item)"
          >
            Elimina 
          <v-icon>mdi-delete</v-icon>
          </v-btn>

          <msp-confirm ref="confirmDelete"></msp-confirm>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="openModal(item)"
          >Attività <i class="msp-icon-attivita"></i>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</div>
</template>
<script type="module">
import env from "@mixins/environment.js"
import _calendario from "@src/js/vue/mixins/calendario.js";
import _record from "@mixins/record-segmenti.js"
import mspConfirm from '@components/MspConfirm.vue';


const component = {
    props: {
        target: {
            type: Object,
            default: null,
        },
        data: {
            type: Array,
            default: ()=>[],
        },
        best: {
            type: Array,
            default: ()=>[],
        },
        headers: {
            type: Array,
            default: ()=>[],
        },
    },
    mixins: [
        env,
    ],
    components: {
        mspConfirm,
    },
    data() {
        return {
            dialog: false,
            item: null,
        }
    },
    computed: {
        stringTarget: function () {
            if (!(this.item && this.item.target)) {
                return "";
            }
            return "Prestazione "+this.item.target.stringValue;
        },
    },
    mounted() {
    },
    watch: {
    },
    methods: {
    async deleteItem(item) {
      if (
        await this.$refs.confirmDelete.open(
          "Elimina",
          "Stai cancellando solo il record, non l'attività svolta."
        )
      ) {
        this.deleteConfirm(item);
        this.dismissModal();
      }
    },
    deleteConfirm(item) {
        this.$emit("delete", item.id);
    },
    dismissModal: function () {
        this.dialog = false;
    },
        openModal: function (item) {
            this.$store.dispatch("calendario/setDialog",true);
            if (!item.idAllenamento) { return; }
            const paramId = "/"+item.idAllenamento;
            this.$router.push("/calendario/attivita/view"+paramId);
        },
        onClickRow: function (item) {
            this.item = {
                ...item,
                target: this.target
            };
            this.dialog = true;
        },
        formatDate: _calendario.ymd2dateShort,
        renderHeaders: function (headers) {
            return headers.map(this.renderHeader);
        },
        renderHeader: function (header) {
            let render = `<span>${header.text}</span>`;
            return render;
        },
        renderBest: function (item) {
            const bestIndex = this.findBestIndex(item);
            if (bestIndex == -1) return "";
            const best = _record.configBest[bestIndex];
            return `<span class='badge-best' style='--color:${best.color}'>${best.badge}</span>`;
        },
        findBestIndex: function (item) {
            if (!(this.best && this.best.length)) return -1;
            const index = this.best.findIndex((el)=>{
                return ( el.dataRecord == item.dataRecord )
                && ( el.risultato == item.risultato )
            });
            return index;
        },
    }
};
export default component;
</script>

<style lang="scss">
.badge-record {
    display: inline-block;
}
.badge-best {
    display: flex;
    justify-content: center;
    align-items: center;
    --size: 30px;
    background-color: var(--color);
    border-radius: 100%;
    width: var(--size);
    height: var(--size);
}
.msp-tabella-risultato {
    th {
        vertical-align: top;
        padding: 5px 16px !important;
    }
    td {
        white-space: nowrap !important;
    }
    .data {
        font-size: 0.8em;
    }
    .result {
        font-weight: bold;
    }
    .header-um {
        font-size: 0.9em;
    }
    .header-multiline {
        display: inline-block;
        span {
            display: block;
        }
    }
}
</style>
